<template>
  <ion-page>
    <ion-content>
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="tab1" href="/tabs/search">
            <ion-icon :icon="searchCircleOutline" />
            <ion-label>{{ $t("tabs.Search") }}</ion-label>
          </ion-tab-button>
          
          <ion-tab-button tab="tab2" href="/tabs/product/list">
            <ion-icon :icon="addCircleOutline" />
            <ion-label>{{ $t("tabs.Rent_out") }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab3" href="/tabs/order/list">
            <ion-icon :icon="listCircleOutline" />
            <ion-label>{{ $t("tabs.Orders") }}</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="tab4" href="/tabs/chats">
          <!-- <ion-tab-button tab="tab4" href="/chats"> -->
            <ion-icon :icon="chatboxOutline" />
            <ion-label>{{ $t("tabs.Chats") }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab5" href="/tabs/profile" v-if="user_profile.id !== 4">
            <ion-icon :icon="personCircleOutline" />
            <ion-label>{{ $t("tabs.Profile") }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="tab5" href="/tabs/moderations" v-if="user_profile.id == 4">
            <ion-icon :icon="personCircleOutline" />
            <ion-label>{{ $t("tabs.Moderation") }}</ion-label>
          </ion-tab-button>

          <div class="indicator"></div>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonContent, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { searchCircleOutline, addCircleOutline, listCircleOutline, chatboxOutline, personCircleOutline, images, square, triangle } from 'ionicons/icons';
import {mapGetters} from 'vuex';

export default {
  name: 'Tabs',
  components: { IonContent, IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  computed:{
    ...mapGetters('user',{
      user_profile:'currentUser',
      app_config:'appConfig'
    })
  },

  setup() {
    return {
      addCircleOutline,
      listCircleOutline,
      searchCircleOutline,
      personCircleOutline,
      images, 
      chatboxOutline,
      square, 
      triangle,
    }
  }
}
</script>

<style scoped>
/*
ion-tab-button::part(native) {
  overflow: initial;
}


ion-tabs ion-tab-bar 
{
  height: 50px;
	display: flex;
  position: relative;
  contain: initial;
  background: #FFF;
}

ion-tabs ion-tab-bar ion-tab-button 
{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: calc(100vw/5);
	text-align: center;
  height: 50px;
	font-weight: 500;
   background: #FFF;
   z-index: 2;
   max-width: unset;
}
ion-tabs ion-tab-bar ion-tab-button ion-icon 
{
	position: relative;
	display: block;
	line-height: 55px;
	font-size: 1.5em;
	text-align: center;
	transition: 0.5s;
	color: var(--clr);
}
ion-tabs ion-tab-bar .tab-selected ion-icon 
{
	transform: translateY(-35px);
	color: #2196f3;
}
ion-tabs ion-tab-bar ion-tab-button ion-label 
{
	position: absolute;
	background: #2196f3;
	color: #fff;
	padding: 6px 7px;
	border-radius: 12px;
	font-weight: 600;
	font-size: 1.2em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	opacity: 0;
  top: 16px;
	transform: translateY(20px);
}
ion-tabs ion-tab-bar .tab-selected ion-label
{
	transform: translateY(-4px);
	opacity: 1;
}

ion-tabs ion-tab-bar ion-tab-button ion-icon{
  font-size: 2.8em;
}

.indicator 
{
  position: absolute;
  top: -34px;
  left: 8px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  z-index: 1;
  width: calc(100vw/5 - 15px);
  height: 71px; 
}
.indicator::before 
{
	content: '';
	position: absolute;
	top: 5px;
	left: -28px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: transparent;
	box-shadow: 15px 18px #fff;
}
.indicator::after 
{
	content: '';
	position: absolute;
	top: 5px;
	right: -28px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: transparent;
	box-shadow: -15px 18px #fff;
}

.plt-tablet .indicator::before, .plt-desktop .indicator::before 
{
    content: '';
    position: absolute;
    top: 5px;
    left: -25px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 13px 19px #fff;
}

.plt-tablet .indicator::after, .plt-desktop .indicator::after  
{
	content: '';
	position: absolute;
	top: 5px;
	right: -25px;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: transparent;
	box-shadow: -13px 19px #fff;
}



ion-tabs ion-tab-bar ion-tab-button:nth-child(1).tab-selected ~ .indicator
{
	transform: translateX(calc(100vw/5 * 0));
}
ion-tabs ion-tab-bar ion-tab-button:nth-child(2).tab-selected ~ .indicator
{
	transform: translateX(calc(100vw/5 * 1));
}
ion-tabs ion-tab-bar ion-tab-button:nth-child(3).tab-selected ~ .indicator
{
	transform: translateX(calc(100vw/5 * 2));
}
ion-tabs ion-tab-bar ion-tab-button:nth-child(4).tab-selected ~ .indicator
{
	transform: translateX(calc(100vw/5 * 3));
}
ion-tabs ion-tab-bar ion-tab-button:nth-child(5).tab-selected ~ .indicator
{
	transform: translateX(calc(100vw/5 * 4));
}*/
</style>
