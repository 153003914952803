export default {
  namespaced: true,

  state: () => ({
    user: null,
  }),

  mutations: {
    SET_USER_NAME(state, name) {
      state.user.name = name;
    },
  },

  actions: {
    setName({ commit }, newValue) {
      commit("SET_USER_NAME", newValue);
    },
  },

  getters: {
    authUser: (state) => {
      return state.user;
    },
  }
}