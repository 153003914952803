import amplitude from 'amplitude-js';
import { toastController } from '@ionic/vue';
import api from "@/lib/api";

//import { getPlatforms } from '@ionic/vue';
import { App } from '@capacitor/app';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';

const lib = {
  timeoutLastVisit: {},
  user_id () { 
    let up = JSON.parse(window.localStorage.getItem('user_profile'));

    if (up && typeof up.id !== 'undefined'){
      return up.id;
    } else {
      console.log(0);
      return 0;
    }
  },

  goToLink(url){
    var browser = InAppBrowser.create(url);
    return browser;
  },
  
  getAppInfo: async function(){
    console.log('get app version');
    if (typeof App !== 'undefined'){
      //console.log(App);
      var res = await App.getInfo().then((res) => {
        console.log(res);
        return Promise.resolve(res);
      }).catch( (err) => {
        console.log(err);
        var v = {'version': '0.0'};
        console.log(v);
        return  Promise.resolve(v);
      });

      return res;
    }
  },

  getGlobalVars: function(){
    var t = localStorage.getItem('global_vars');
    return t;
  },

  getGlobalVar: function(param){
    var t = localStorage.getItem('global_vars');
    if (t){
      var obj = JSON.parse(t);
      if (typeof obj[param] !== 'undefined'){
        return obj[param];
      } else{
        return null;
      }
    }
  },

  resetGlobalVars: function(){
    localStorage.removeItem('global_vars');
  },
  
  setGlobalVar: function(param_name, value){
    var t = JSON.parse(localStorage.getItem('global_vars'));
    if (!t) t = {};
    t[param_name] = value;
    localStorage.setItem('global_vars', JSON.stringify(t));
  },

  getCurrentLocale: function(){
    return lib.getGlobalVar('current_locale');
  },
  
  getLanguages: function(){
    var langs = {};
    langs['en'] = 'English';
    langs['ru'] = 'Русский';
    return langs
  },

  getAppVersion: async function(force_update){
    if (typeof force_update == 'undefined') force_update = false;

    var app_version = this.getGlobalVar('app_version');
    console.log(app_version);
    if (!app_version || force_update){
      var app_info = await this.getAppInfo();
      console.log(app_info);
      this.setGlobalVar('app_version', app_info.version);
      return app_info.version;
    } else {
      return app_version;
    }
  },
  logEvent(event, params){
    if (typeof params == 'undefined') params = {};
    let up = JSON.parse(window.localStorage.getItem('user_profile'));
    if (up && typeof up.id !== 'undefined'){
      amplitude.getInstance().setUserId(up.id);
      amplitude.getInstance().setUserProperties(up);    
    }
    
    amplitude.getInstance().logEvent(event, params);
    
  },

  clearLastVisitTimer(){
    clearTimeout(this.timeoutLastVisit);
  },

  pingLastVisit () {
    // eslint-disable-next-line
    this.timeoutLastVisit = setTimeout(function(){
      api.trans('users/lastvisit', {}, 'post');
      lib.pingLastVisit();
    }, 5000);
    
    //console.log('updatePushCredentials!');
  },

  
  async openSaveToast(msg, color) {
    if (typeof color == 'undefined') color = 'success';
    const toast = await toastController
      .create({
        message: msg,
        duration: 2000,
        color: color
      })
    return toast.present();
  },

  async openToast(msg, color) {
    if (typeof color == 'undefined') color = 'success';
    const toast = await toastController
      .create({
        message: msg,
        duration: 2000,
        color: color
      })
    return toast.present();
  },
}
export default lib
