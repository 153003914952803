import { createStore } from 'vuex'
import auth from './auth'
import user from './user'
import storeproducts from './storeproducts'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {

  },
  modules: {
    auth,
    user,
    storeproducts
  }
})
