
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'


import VueGoogleMaps from '@fawmi/vue-google-maps';
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';


/* Theme variables */
import './theme/variables.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

//import store from './store/index.js';
import store  from './store'  //use curly braces to around store.

import messages  from './locales/texts'  //use curly braces to around store.

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_API_KEY,
        libraries: "places, geocode"
    },
})



//app.config.globalProperties.api_host = 'http://dobro.na4u.ru/api/';
//app.config.globalProperties.$axios = axios;





router.isReady().then(() => {
  console.log('isReady');
  store.dispatch("user/checkAuth").then((is_auth) => {
    console.log('checkAuth', is_auth);
    //console.log()
    if (is_auth){
      store.dispatch("user/getConfig").then(() => {
        store.dispatch("storeproducts/initStore").then(() => {
          app.mount('#app');
          defineCustomElements(window);
        });
      });
    } else {
      store.dispatch("user/getPublicConfig").then(() => {
          app.mount('#app');
          defineCustomElements(window);
      });
    }
  });
});