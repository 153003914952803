<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, provide } from 'vue';
import axios from 'axios';
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
import { computed } from 'vue'
//import OneSignal from 'onesignal-cordova-plugin';
import amplitude from 'amplitude-js';
import lib from "@/lib/lib";
//import {store} from './store';  
//import { Capacitor } from '@capacitor/core';
//import {mapGetters,mapActions} from 'vuex';

// eslint-disable-next-line
let app_version = '0.0';
export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  mounted(){
    //this.name = '1111';
    const locale = localStorage.getItem('locale');
    if (locale) this.$i18n.locale = locale;
    lib.setGlobalVar('current_locale', this.$i18n.locale);
  },

  setup() {
    //const user_current = ref();
    //const store = useStore();
    const store = useStore();
    // eslint-disable-next-line no-unused-vars
    const router = useRouter();
    lib.resetGlobalVars();
    lib.getAppVersion(true);
    //provide('api_host', 'https://dobro.na4u.ru/api/');
    provide('user_profile', {});

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    lib.setGlobalVar('dark_mode', prefersDark.matches);
    //console.log(prefersDark);

    console.log(window);

    //console.log(store);
    //console.log(this.store.getters["auth/authUser"].name);
    amplitude.getInstance().init("6262aa06bb4f880e255bea977cb19510");

    amplitude.getInstance().logEvent('app setup!');
    
  
    var t = localStorage.getItem('token');

    if (t){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + t;
      provide('token', t);    
    }

    var u_profile = JSON.parse(localStorage.getItem('user_profile'));
    console.log('setup app');
    if (u_profile){
      lib.pingLastVisit();

      //provide('user_profile', JSON.parse(u_profile));   
      console.log(u_profile);

      // amplitude.getInstance().setUserId(u_profile.id);
      // amplitude.getInstance().setUserProperties({'gender': 'female', 'sign_up_complete': true});
      // console.log(u_profile);
    }

    //
    

    if (typeof OneSignal != 'undefined'){
      
      console.log(typeof OneSignal);

      document.addEventListener("deviceready", OneSignalInit, false);
      // Call this function when your app starts
        // eslint-disable-next-line
       function OneSignalInit() {
          // Uncomment to set OneSignal device logging to VERBOSE  
          // window.plugins.OneSignal.setLogLevel(6, 0);
          // NOTE: Update the setAppId value below with your OneSignal AppId.
          window["plugins"].OneSignal.setAppId("36784e22-74fd-4362-a7ce-2941cf4ae06a");
          window["plugins"].OneSignal.setNotificationOpenedHandler(function(jsonData) {
            if (typeof jsonData.notification.additionalData !== 'undefined' && typeof jsonData.notification.additionalData.state !== 'undefined'){  
              router.push({name : jsonData.notification.additionalData.state.name, params: jsonData.notification.additionalData.state.params, replace: true });
            }

              console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
          });

          // iOS - Prompts the user for notification permissions.
          //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.

          // eslint-disable-next-line
          window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
              //console.log("User accepted notifications: " + accepted);
          });
        }


      // eslint-disable-next-line
      // function OneSignalInit(){
      //   // Uncomment to set OneSignal device logging to VERBOSE  
      //   // OneSignal.setLogLevel(6, 0);

      //   // NOTE: Update the setAppId value below with your OneSignal AppId.
      //   // eslint-disable-next-line
      //   OneSignal.setAppId("36784e22-74fd-4362-a7ce-2941cf4ae06a");
      //   // eslint-disable-next-line
      //   OneSignal.setNotificationOpenedHandler(function(jsonData) {
      //     // var push_data = JSON.parse(jsonData);
      //     // if(typeof push_data['state'] !== 'undefined'){
            
      //     // }
      //     console.log(jsonData);
      //     lib.logEvent('pushOpened');
      //     if (typeof jsonData.notification.additionalData !== 'undefined' && typeof jsonData.notification.additionalData.state !== 'undefined'){  
      //       router.push({name : jsonData.notification.additionalData.state.name, params: jsonData.notification.additionalData.state.params, replace: true });
      //     }
      //       console.log(jsonData);

      //   });

      //   // iOS - Prompts the user for notification permissions.
      //   //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
      //   // eslint-disable-next-line
      //   OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
      //       console.log("User accepted notifications: " + accepted);
      //   });
      // }
    }


    return {
      // access a state in computed function
      user: computed(() => store.state.user),

      // access a getter in computed function
      //double: computed(() => store.getters.double)
    }
    
    
  }
});



</script>