import { createRouter, createWebHistory } from '@ionic/vue-router';
//import { RouteRecordRaw } from 'vue-router';
// import Home from '../views/Home.vue'
// import Profile from '../views/Profile.vue'
import Tabs from '../views/Tabs.vue'

const routes = [
//const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/search'
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
  },
 {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterPage.vue"),
  },
  {
    path: "/remind",
    name: "remind",
    component: () => import("@/views/RemindPage.vue"),
  },
  {
    path: "/user/:id",
    name: "user",
    component: () => import("@/views/UserProfilePage.vue"),
  },

  // {
  //   path: "/chats",
  //   name: "chats",
  //   component: () => import("@/views/ChatsPage.vue"),
  // },
  // {
  //   path: "/chat/:id",
  //   name: "chat",
  //   component: () => import("@/views/ChatsPage.vue"),
  // },


  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: 'profile'
      },
      {
        path: "chats/",
        name: "chats",
        component: () => import("@/views/ChatsPage.vue"),
      },
      {
        path: "chat/:id",
        name: "chat",
        component: () => import("@/views/ChatsPage.vue"),
      },   
      
      {
        path: 'search/',
        name: 'search',
        component: () => import('@/views/Search.vue')
      },

      {
        path: 'profile/',
        name: 'myprofile',
        component: () => import('@/views/Profile.vue')
      },      
      {
        path: 'profile/:id',
        name: 'userprofile',
        component: () => import('@/views/Profile.vue')
      },
      {
        path: 'product/add',
        name: 'productadd',
        component: () => import('@/views/ProductEdit.vue')
      },

      {
        path: 'product/list',
        name: 'productslist',
        component: () => import('@/views/ProductList.vue')
      } ,

      {
        path: 'product/page/:id',
        name: 'productpage',
        component: () => import('@/views/ProductPage.vue')
      } ,
      {
        path: 'product/edit/:id',
        name: 'productedit',
        component: () => import('@/views/ProductEdit.vue')
      },   

      {
        path: 'order/list',
        name: 'orderslist',
        component: () => import('@/views/OrderList.vue')
      } ,  
      {
        path: 'order/list/:segment',
        name: 'orderslistsegment',
        component: () => import('@/views/OrderList.vue')
      },       
      {
        path: 'order/page/:id',
        name: 'orderpage',
        component: () => import('@/views/OrderPage.vue')
      } ,
      {
        path: 'address/edit/:id',
        name: 'addressedit',
        component: () => import('@/views/AddressEdit.vue')
      },  
      {
        path: 'address/add',
        name: 'addressadd',
        component: () => import('@/views/AddressEdit.vue')
      },  
      {
        path: 'moderations/',
        name: 'moderations',
        component: () => import('@/views/ModerationPage.vue')
      }       
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
