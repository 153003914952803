import api from '@/lib/api'
import lib from '@/lib/lib'
import { Capacitor } from '@capacitor/core';
//import OneSignal from 'onesignal-cordova-plugin';

export default {
  namespaced: true,

  state: () => ({
    currentUser: {},
    appConfig: {},
    authChecked: false

  }),

  mutations: {
    setCurrentUser(state, payload){
      state.currentUser = payload;
    },

    // eslint-disable-next-line
    setUserPushCredentials(state, payload){
      // {push_userid, push_usertoken, platform}
      //state.currentUser.push_credentials = payload;
    },

    setConfig(state, payload){
      state.appConfig = payload;
    },

    checkAuth(state, payload) {
      state.currentUser = payload.user;
      state.authChecked = payload.authChecked;
    },    
  },
  actions: {
    // eslint-disable-next-line
    getCurrentUser ({commit, dispatch}, data) {
      var user_id = lib.user_id();
      api.trans('users/get/' + user_id, data, 'get').then( (response)=> {
        if (Capacitor.isNativePlatform() && typeof OneSignal !== 'undefined'){
          // eslint-disable-next-line
          OneSignal.getDeviceState(function(stateChanges) {
            console.log('OneSignal getDeviceState: ' + JSON.stringify(stateChanges));
              response.data.push_userid = stateChanges.userId;
              response.data.push_usertoken = stateChanges.pushToken;
              dispatch("updatePushCredentials", {user_id: user_id, push_userid: stateChanges.userId, push_usertoken: stateChanges.pushToken}).then(() => {
              commit('setCurrentUser', response.data);    
            });
          });
        } else {  
          commit('setCurrentUser', response.data);    
        }
      });
    },

    async getConfig ({commit}, data) {
      const response = await api.trans('users/config', data, 'get')
      console.log('getConfig!');
      commit('setConfig', response.data);
    },

    async getPublicConfig ({commit}, data) {
      const response = await api.trans('users/configpublic', data, 'get')
      console.log('getConfig!');
      commit('setConfig', response.data);
    },

    

    async updatePushCredentials ({commit}, data) {
      // eslint-disable-next-line
      const response = await api.trans('users/onesignal/store', data, 'post')
      console.log('updatePushCredentials!');
      commit('setUserPushCredentials', data);
    },

      
    checkAuth({ commit }) {
      return new Promise((resolve) => {
        var user_id = lib.user_id();
        if (user_id){
          
          api.trans('users/get/'+lib.user_id(), {}, 'get').then((response) => {
            console.log(response.data);
            commit('setCurrentUser', response.data);
            resolve(true);
          })
        } else {
          resolve(false);
        }
      });
    },
    
  },
  getters: {
    currentUser(state){
        return state.currentUser;
    },

    appConfig(state){
      return state.appConfig;
    }
  }
}
