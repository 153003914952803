// eslint-disable-next-line
const messages = {
  en: {
    login: {
      Title: "Log In",
      Password: "Password",
      Registration: "Registration",
      Error_login: "Error! Check your login and password",
      Forgot_password: "Forgot password",
      Login: "Log In",
      Guest_login:"Login as Guest"
    },
    address: {
      Add_address: "Add address",
      Edit_address: "Edit address",
      Enter_address: "Enter address",
      Save: "Save",
      Delete: "Delete",
      Data_saved: "Data saved.",
      Cancel: "Cancel",
      Delete_q: "Delete?",
      Your_addresses: "Your addresses",
      Select: "Select",
      Address_edit: "Address edit",
      Address_add: "Address add",
      Back: "Back"
    },
    chats: {
      Chats: "Chats",
      Rooms_empty: "No chats",
      Room_empty: "Chat empty",
      New_messages: "New messages",
      Message_deleted: "Message deleted",
      Messages_empty: "No messages",
      Delete: "Delete",
      Conversation_started: "Chat started: ",
      Type_message: "Write message",
      Search: "Search",
      Is_online: "online ",
      Last_seen: "last online ",
      Is_typing: "is typing..."

    },
    moderation: {
      Moderation: "Moderation",
      Verifiation: "Verification",
      Reports: "Reports",
      Canceled_by_you: "canceled by you",
      Account_verification: "Account verification",
      Verification_complete_q: "Verification complete?",
      Verification_not_complete_q: "Verification isn't complete?",
      Cancel: "Cancel",
      Report_accepted_q: "Report accepted?",
      Report_reject_q: "Regect report?",
      Yes: "Yes"
    },
    orderlist: {
      Your_orders: "Your orders",
      Orders_to_you: "Orders to you",
      Reg_To_See: "Register or login to your account to manage your orders.",
      Login: "Log In",
      Sure_q: "Are you sure?"
    },

    orderpage: {
      Order: "Order",
      From: "from",
      Order_canceled_renter: "Order canceled by renter.",
      Order_canceled_owner: "Order canceled by owner.",
      Order_complete: "Order complete",
      Wait_payment: "The user will soon complete the payment and you will be able to approve or reject the application.",
      Order_paid: "Order successfully paid!",
      Money_reserved: "The money for the order is reserved in a secure account and will be credited to your card after the transfer of the property.",
      Confirm_transfer: "Confirm transfer",
      No_payment_way: "We do not know where to make the payment for this application. Add a payout card in your profile and return to this application.",
      Add_card: "Add card to profile",
      Transfer_in_progress: "Property in the process of transfer",
      Transfer_confirm: "Now the renter must confirm the acceptance of the property using an SMS code.",
      Property_in_use: "Property in use",
      Transfer_act: "Transfer act",
      Confirm_refund: "Confirm return",
      Confirm_refund_message: "Check the property and if everything is in order, confirm the return. The deposit will be returned to the renter.",
      Return_accept: "Confirm return",
      Refund_complete: "Property returned",
      Sum: "Sum",
      Commission: "Commission",
      Payout: "Payout",
      Money_frized: "The money is frozen in a secure account. Contact the owner to arrange the transfer of the rental property.",
      Confirm_get: "Confirm transfer",
      Check_and_confirm: "Check the property and confirm receipt.",
      Confirm_get_accept: "Confirm transfer",
      Return_property_message: "Return the property at the end of it's rent time. The transfer of property is recorded ",
      In_act_property: " in the act of transfer of property",
      Return_property: "Return_property",
      Return_proccess: "Property is returning",
      Return_proccess_message: "Now the owner must check everything and confirm the return of the property.",
      Return_complete: "Property returned",
      Deal_success: "The deal was completed successfully.",
      Owner: "Owner",
      Rent_created: "Rent created",
      From_date:"From what date",
      To_date:"By what date",
      Price: "Price",
      Deposit: "Deposit",
      Address: "Delivery address",
      Enter: "Enter",
      To_pay: "To the payment",
      Contact_owner: "Contact owner",
      Contact_renter: "Contact renter",
      Cancel: "Cancel",
      Cancel_order_q: "Cancel order?",
      No: "No",
      Yes: "Yes",
      Enter_name: "Enter the name of the item.",
      Back: "Back"
    },

    productedit: {
      Add_property: "Add property",
      Edit_property: "Edit property",
      Read_rules_1: "Please, read ",
      Read_rules_2: "rules of publising property",
      Read_rules_3: " in the app. In case of violation, your account will be blocked.",
      Category: "Category",
      Category_choose: "Choose category",
      Cancel: "Cancel",
      Caption: "Caption",
      Price: "Price (₽/day)",
      Address: "Address",
      Address_enter: "Enter address",
      Address_choose: "Address choose",
      Additionally: "More information",
      Deposit: "Deposit",
      Short_description: "Short description",
      Short_description_placeholder: "If needed. Maximum 255 characters.",
      Minimum_rental_period: "Min. rental period",
      Minimum_rental_period_placeholder: "in days",
      Progressive_discount: "Progressive discount",
      Progressive_discount_description: "Automatically assigns a discount to the user depending on the rental period.",
      Days: "days",
      Delivery: "Delivery",
      With_the_performer: "Departure of the performer",
      Submit: "Submit",
      Save: "Save",
      Delete: "Delete",
      CM_title: "Rate app",
      CM_message: "Your rating will help us a lot in promoting the application. Thanks for your support!",
      CM_cancelButtonLabel: "No thanks",
      CM_laterButtonLabel: "Ask later",
      CM_rateButtonLabel: "⭐ Rate",
      CM_yesButtonLabel: "Cool 🔥",
      CM_noButtonLabel: "So so 😥",
      CM_appRatePromptTitle: "Do you like the app?",
      CM_feedbackPromptTitle: "Write your suggestions?",
      Delete_q: "Delete?",
      No: "No",
      Yes: "Yes",
      Save_changes: "Changes saved.",
      Enter_caption: "Enter item caption.",
      Categories: "Categories",
      Categories_choose: "Select the main item category"
    },

    productlist: {
      Your_property: "Your property",
      Login_warn: "Register or log in to your account to rent out things.",
      Login: "Login",
      Buy_subscription: "Buy subscription",
      Instruction_1: "Please read this guide before renting out your belongings. «",
      Instruction_2: "How to rent out things",
      Submited: "submited",
      Not_submited: "not submited",
      Held: "favorite",
      Views: "views",
      Add: "Add",
      Profile_warn: "The app automatically creates a rental agreement between you and the renter. Your profile is currently missing data. Go to your profile and add information.",
      To_profile: "Go to profile",
      Expand_tariff: "Expand tariff",
      Cancel_order_q: "Cancel order?",
      No: "No",
      Yes: "Yes",
      User_property: "User property",
      Guide: "Guide for property rent out",
      Product_rules: "Rental rules"
    },
    productpage: {
      Report: "report",
      Added: "Added",
      Owner: "Owner",
      To_chat: "To chat",
      Write: "Write",
      Minimum_rental_period: "Min. rental period",
      Days: "d.",
      Price: "Price (₽/day)",
      Deposit: "Deposit",
      Progressive_discount: "Progressive discount",
      Delivery: "Delivery",
      With_the_performer: "Departure of the performer",
      Address: "Address",
      To_rent: "Rent",
      From_date:"From what date",
      To_date:"By what date",
      Price_2: "Price",
      Delivery_address: "Delivery address",
      Enter: "Enter",
      Book: "Book",
      To_favorite: "Add to favorite",
      From_favorite: "Delete from favorite",
      Login_message: "Register or login to your account to rent items and add items to your favorites.",
      Login: "Login",
      Address_choose: "Address select",
      Sure_q: "Sure?",
      Data_saved: "Data saved",
      Date_not_available: "This dates are not available :-(",
      Enter_name: "Enter item name.",
      Report_q: "Submit a report about this item?",
      Cancel: "Cancel",
      Yes: "Yes",
      Back: "Back"
    },
    profile: {
      Interface_language:' Interface language',
      Your_profile: "Your profile",
      Name: "Name",
      Surname: "Surname",
      Patronymic: "Patronymic",
      Phone: "Phone number",
      Verified: "Verified",
      Complete_verification: "Complete verification",
      Passport_data: "Passport data",
      Passport_number: "Passport ID",
      Passport_issue: "Passport issue by",
      Passport_code_division: "Code of division",
      Passport_date: "When issued",
      Registration_address: "Registration address",
      Registered_index: "Postcode",
      Registered_state: "State",
      Registered_city: "City",
      Registered_street: "Street",
      Registered_house: "House",
      Registered_flat: "Flat",
      Your_addresses: "Your addresses",
      Your_cards: "Your cards",
      Save: "Save",
      Logout: "Logout",
      Delete_account: "Delete account",
      Login_warn: "Register or login to your account to edit your profile.",
      Login: "Login",
      Account_verification: "Account verification",
      Delete_account_q: "Delete account?",
      No: "No",
      Yes: "Yes",
      Delete_message: "Removal request accepted. The data will be deleted within 24 hours.",
      Enter_name: "Enter name.",
      Enter_surname: "Enter surname.",
      Enter_phone: "Enter phone.",
      Saved_successfully: "Saved successfully!"
    },
    registerpage: {
      Registration: "Registration",
      Name: "Name",
      Surname: "Surname",
      Password: "Password",
      Login: "Login",
      Registration_success: "You have successfully registered! Go to profile.",
      Back: "Back"
    },
    remindpage: {
      Password_remind: "Password recovery",
      Password_sent: 'A new password will be sent to your email. Also check your "spam" folder.',
      Reset: "Reset",
      Remind: "Remembered"
    },
    search: {
      Item_search: "Search",
      All: "All",
      Favorite: "Favorite",
      Find_me: "find me",
      Loading: "Loading...",
      Price: "₽/day",
      Search_settings: "Search settings",
      Category: "Category",
      Category_select: "Category select",
      Minimum_price: "Min. price",
      Maximum_price: "Max. price",
      Show_results: "Show results",
      Reset_filters: "Reset filters",
      Categories: "Categories"
    },
    tabs: {
      Search: "Search",
      Rent_out: "Rent out",
      Orders: "Orders",
      Chats: "Chats",
      Profile: "Profile",
      Moderation: "Moderation"
    },
    userprofile: {
      User_profile: "User profile",
      Phone: "Phone",
      Message: "Message",
      To_chat: "To chat",
      Write: "Write",
      Login_warn: "Register or login to your account to contact the owners of the items.",
      Login: "Login",
      Enter_name: "Enter name.",
      Enter_surname: "Enter surname.",
      Enter_phone: "Enter phone.",
      Back: "Back"
    },
    cardlist: {
      Set_main: "Make primary",
      Main: "Primary",
      Pay_by_card: "Pay by card",
      Add_card: "Add card",
      Cancel: "Cancel",
      Delete_q: "Delete?",
      Delete: "Delete",
      Your_cards: "Your cards",
      Select_card: "Select card",
      Select: "Select"
    },
    ordercalendar: {
      Select_rent_time: "Select rent time",
      Select_rent_start_end: "Set the start and end date of the rental period.",
      Code_wrong: "The code has not been verified. Try again"
    },
    orderconfirm: {
      Code_wrong: "The code has not been verified. Try again",
      Confirm_get_property: "Confirm receipt of property",
      Check_property: "Check the property - appearance and functionality. Read the terms of the lease agreement and the act of acceptance and transfer of property and sign them using the SMS code.",
      Agree_1: "The property is in good condition, I ",
      Agree_2: "have read and agree with the contract.",
      Get_sms: "Get SMS-code",
      Confirm: "Confirm",
      New_sms: "Repeat SMS after",
      Sec: "sec.",
      Get_code: "Request a new code",
      Confirm_give_property: "Confirm the transfer of property",
      Check_and_confirm: "Read the terms of the rent agreement and sign it using the SMS code. Check the data in the contract and the renter's passport BEFORE transferring the property."
    },
    orderpayment: {
      Order_payment: "Order payment",
      Complete_payment: "Complete the application payment within 5 minutes.",
      Agreement_1: "By paying for the order, you confirm your agreement with",
      Agreement_2: "the terms of the public offer",
      Agreement_3: " of the «Dobro v Delo» service, as well as with the",
      Agreement_4: "terms of the rent agreement",
      Agreement_5: "for your order.",
      Pay_new_card: "Pay by new card",
      Order_document: "Rent contract",
      Public: "Public offer"
    },
    orderreturn: {
      Property_return_confirm: "Confirm return of property",
      Property_return_message: "Attention, you return the property to the owner. The owner will receive a request to confirm the action, check everything and confirm the return of the deposit if the deposit was paid.",
      Property_return: "Property return",
      Property_check: "Check the property - appearance and functionality. Confirm receipt. The deposit will be returned to the renter.",
      Property_okey: "The property is in good condition, I have no claims against the Renter.",
      Confirm_return: "Confirm return",
      Return_property:"Return property",
      Code_wrong: "The code has not been verified. Try again",
      Sure_q: "Sure?",
      No: "No",
      Yes: "Yes",
      Return_act: "The act of property transfer",
      Close: "Close"
    },
    productimages: {
      Take_photo: "Take photo",
      From_gallery: "From gallery",
      Photos: "Photos"
    },
    store: {
      Products: "Purchases",
      Month_subscription: "Subscription Period: Month",
      Active: "Active",
      Restore_purchases: "Restore purchases",
      Privacy_policy: "Privacy policy",
      Eula: "User Agreement (EULA)",
      Purchases_restored: "Purchases restored.",
      More_ads: "Create more orders!"
    },
    verifiation: {
      Documents_check: "The document has been sent for review.",
      Load_photo: "Upload a photo of your identity document",
      Add_photo: "Add photo"
    }
  },
  ru: {
    login: {
      Title: "Вход",
      Password: "Пароль",
      Registration: "Регистрация",
      Error_login: "Ошибка! Проверьте логин и пароль",
      Forgot_password: "Забыл пароль",
      Login: "Войти",
      Guest_login: "Войти как Гость"
    },
    address: {
      Add_address: "Добавить адрес",
      Edit_address: "Редактировать адрес",
      Enter_address: "Укажите адрес",
      Save: "Сохранить",
      Delete: "Удалить",
      Data_saved: "Изменения сохранены.",
      Cancel: "Отмена",
      Delete_q: "Удалить?",
      Your_addresses: "Ваши адреса",
      Select: "Выбрать",
      Address_edit: "Редактирование адреса",
      Address_add: "Добавление адреса",
      Back: "Назад"

    },
    chats: {
      Chats: "Чаты",
      Rooms_empty: "Нет чатов",
      Room_empty: "Чат пуст",
      New_messgaes: "Новые сообщения",
      Message_deleted: "Сообщение удалено",
      Messages_empty: "Сообщений нет",
      Delete: "Удалить",
      Conversation_started: "Чат начат:",
      Type_message: "Печатайте сообщение",
      Search: "Поиск",
      Is_online: "в сети ",
      Last_seen: "был в сети ",
      Is_typing: "печатает сообщение..."

    },
    moderation: {
      Moderation: "Модерация",
      Verifiation: "Верификация",
      Reports: "Жалобы",
      Canceled_by_you: "отменено вами",
      Account_verification: "Верификация аккаунта",
      Verification_complete_q: "Верификация пройдена?",
      Verification_not_complete_q: "Верификация не пройдена?",
      Cancel: "Отмена",
      Report_accepted_q: "Жалоба принята?",
      Report_reject_q: "Отклонить жалобу?",
      Yes: "Да"
    },

    orderlist: {
      Your_orders: "Ваши заказы",
      Orders_to_you: "Заказы вам",
      Reg_To_See: "Зарегистрируйтесь или войдите в свой аккаунт, чтобы управлять своими заказами.",
      Login: "Войти",
      Sure_q:"Уверены?"
    },

    orederpage: {
      Order: "Заказ",
      From: "от",
      Order_canceled_renter: "Заказ отменен арендатором.",
      Order_canceled_owner: "Заказ отменен владельцем.",
      Order_complete: "Заказ оформлен",
      Wait_payment: "Пользователь скоро завершит оплату, и вы сможете подтвердить или отклонить заявку.",
      Order_paid: "Заказ успешно оплачен!",
      Money_reserved: "Деньги за заказ зарезервированы на безопасном счете и будут зачислены вам на карту после передачи имущества.",
      Confirm_transfer: "Подтвердить передачу",
      No_payment_way: "Мы не знаем, куда вам сделать выплату за эту заявку. Добавьте в профиле карту для выплаты и вернитесь в эту заявку.",
      Add_card: "Добавить карту в профиль",
      Transfer_in_progress: "Имущество в процессе передачи",
      Transfer_confirm: "Теперь арендатор должен подтвердить прием имущества с помощью SMS-кода.",
      Property_in_use: "Имущество в использовании",
      Transfer_act: "Акт передачи",
      Confirm_refund: "Подтвердите возврат",
      Confirm_refund_message: "Проверьте имущество и если все в порядке – подтвердите возврат. Залог будет возвращен арендатору.",
      Return_accept: "Подтвердить возврат",
      Refund_complete: "Имущество возвращено",
      Sum: "Сумма",
      Commission: "Комиссия",
      Payout: "Выплата",
      Money_frized: "Деньги заморожены на безопасном счете. Свяжитесь с владельцем, чтобы договориться о передаче арендуемого имущества.",
      Confirm_get: "Подтвердите получение",
      Check_and_confirm: "Проверьте имущество и подтвердите его получение.",
      Confirm_get_accept: "Подтвердить получение",
      Return_property_message: "Верните имущество по окончании срока использования. Передача имущества зафиксирована ",
      In_act_property: "в акте-передачи имущества",
      Return_property: "Вернуть имущество",
      Return_proccess: "Имущество возвращается",
      Return_proccess_message: "Теперь владелец должен все проверить и подтвердить возврат имущества.",
      Return_complete: "Имущество возвращено",
      Deal_success: "Сделка завершена успешно.",
      Owner: "Владелец",
      Rent_created: "Оформлена аренда",
      From_date:"С какой даты",
      To_date:"По какую дату",
      Price: "Стоимость",
      Deposit: "Залог",
      Address: "Адрес доставки",
      Enter: "Указать",
      To_pay: "К оплате",
      Contact_owner: "Связаться с владельцем",
      Contact_renter: "Связаться с арендатором",
      Cancel: "Отменить",
      Cancel_order_q: "Отменить заказ?",
      No: "Нет",
      Yes: "Да",
      Enter_name: "Укажите название вещи.",
      Back: "Назад"
    },

    productedit: {
      Add_property: "Добавить вещь",
      Edit_property: "Редактировать вещь",
      Read_rules_1: "Ознакомьтесь, пожалуйста, ",
      Read_rules_2: "с правилами размещения вещей",
      Read_rules_3: " в приложении. В случае нарушения ваш аккаунт будет заблокирован.",
      Category: "Категория",
      Category_choose: "Выберите категорию",
      Cancel: "Отмена",
      Caption: "Название",
      Price: "Цена (₽/сутки)",
      Address: "Адрес",
      Address_enter: "Указать адрес",
      Address_choose: "Выбор адреса",
      Additionally: "Дополнительно",
      Deposit: "Залог",
      Short_description: "Короткое описание",
      Short_description_placeholder: "Если требуется. Максимум 255 символов.",
      Minimum_rental_period: "Мин. срок аренды",
      Minimum_rental_period_placeholder: "в днях",
      Progressive_discount: "Прогрессивная скидка",
      Progressive_discount_description: "Автоматически назначает скидку пользователю в зависимости от срока аренды.",
      Days: "дней",
      Delivery: "Доставка",
      With_the_performer: "Выезд исполнителя",
      Submit: "Опубликовать",
      Save: "Сохранить",
      Delete: "Удалить",
      CM_title: "Оцените приложение",
      CM_message: "Ваша оценка очень поможет нам в продвижении приложения. Спасибо за вашу поддержку!",
      CM_cancelButtonLabel: "Нет, спасибо",
      CM_laterButtonLabel: "Напомните позже",
      CM_rateButtonLabel: "⭐ Оценить",
      CM_yesButtonLabel: "Огонь 🔥",
      CM_noButtonLabel: "Не очень 😥",
      CM_appRatePromptTitle: "Вам нравится приложение?",
      CM_feedbackPromptTitle: "Напишите свои пожелания?",
      Delete_q: "Удалить?",
      No: "Нет",
      Yes: "Да",
      Save_changes: "Изменения сохранены.",
      Enter_caption: "Укажите название вещи.",
      Categories: "Категории",
      Categories_choose: "Выберите основную категорию вещи."
    },

    productlist: {
      Your_property: "Ваши вещи",
      Login_warn: "Зарегистрируйтесь или войдите в свой аккаунт, чтобы сдавать вещи в аренду.",
      Login: "Войти",
      Buy_subscription: "Купить подписку",
      Instruction_1: "Прежде, чем сдавать свои вещи в аренду, ознакомьтесь с этой памяткой «",
      Instruction_2: "Как сдавать вещи в аренду",
      Submited: "опубликовано",
      Not_submited: "не опубликовано",
      Held: "отложили",
      Views: "просмотров",
      Add: "Добавить",
      Profile_warn: "Приложение автоматически создает договор аренды между вами и арендатором. Сейчас в вашем профиле не хватает данных. Перейдите в профиль и добавьте информацию.",
      To_profile: "Перейти в профиль",
      Expand_tariff: "Расширить тариф",
      Cancel_order_q: "Отменить заказ?",
      No: "Нет",
      Yes: "Да",
      User_property: "Вещи пользователя",
      Guide: "Памятка о сдаче вещей",
      Product_rules: "Правила размещения вещей"
    },
    productpage: {
      Report: "жалоба",
      Added: "Добавлено",
      Owner: "Владелец",
      To_chat: "К чату",
      Write: "Написать",
      Minimum_rental_period: "Мин. срок аренды",
      Days: "дн.",
      Price: "Цена (₽/сутки)",
      Deposit: "Залог",
      Progressive_discount: "Прогрессивная скидка",
      Delivery: "Доставка",
      With_the_performer: "Выезд исполнителя",
      Address: "Адрес",
      To_rent: "Взять в аренду",
      From_date:"С какой даты",
      To_date:"По какую дату",
      Price_2: "Стоимость",
      Delivery_address: "Адрес доставки",
      Enter: "Указать",
      Book: "Забронировать",
      To_favorite: "Добавить в избранное",
      From_favorite: "Убрать из избранного",
      Login_message: "Зарегистрируйтесь или войдите в свой аккаунт, чтобы брать в аренду вещи и добавлять вещи в избранное.",
      Login: "Войти",
      Address_choose: "Выбор адреса",
      Sure_q: "Уверены?",
      Data_saved: "Данные сохранены",
      Date_not_available: "Эти даты не доступны :-(",
      Enter_name: "Укажите название вещи.",
      Report_q: "Отправить жалобу на эту вещь?",
      Cancel: "Отмена",
      Yes: "Да",
      Back: "Назад"
    },
    profile: {
      Interface_language:'Язык приложения',
      Your_profile: "Ваш профиль",
      Name: "Имя",
      Surname: "Фамилия",
      Patronymic: "Отчество",
      Phone: "Телефон",
      Verified: "Верифицирован",
      Complete_verification: "Пройти верификацию",
      Passport_data: "Паспортные данные",
      Passport_number: "Номер паспорта",
      Passport_issue: "Кем выдан",
      Passport_code_division: "Код подразделения",
      Passport_date: "Когда выдан",
      Registration_address: "Адрес регистрации",
      Registered_index: "Почтовый индекс",
      Registered_state: "Область",
      Registered_city: "Город",
      Registered_street: "Улица",
      Registered_house: "Дом",
      Registered_flat: "Квартира",
      Your_addresses: "Ваши адреса",
      Your_cards: "Ваши карты",
      Save: "Сохранить",
      Logout: "Выйти",
      Delete_account: "Удалить аккаунт",
      Login_warn: "Зарегистрируйтесь или войдите в свой аккаунт, чтобы редактировать профиль.",
      Login: "Войти",
      Account_verification: "Верификация аккаунта",
      Delete_account_q: "Удалить аккаунт?",
      No: "Нет",
      Yes: "Да",
      Delete_message: "Заявка на удаление принята. Данные будут удалены в течение 24 часов.",
      Enter_name: "Укажите имя.",
      Enter_surname: "Укажите фамилию.",
      Enter_phone: "Укажите телефон.",
      Saved_successfully: "Успешно сохранено!"
    },
    registerpage: {
      Registration: "Регистрация",
      Name: "Имя",
      Surname: "Фамилия",
      Password: "Пароль",
      Login: "Войти",
      Registration_success: "Вы успешно зарегистрированы! Войдите в кабинет.",
      Back: "Назад"
    },
    remindpage: {
      Password_remind: "Восстановление пароля",
      Password_sent: 'Новый пароль будет выслан на ваш email. Проверьте также папку "спам".',
      Reset: "Восстановить",
      Remind: "Вспомнил"
    },
    tabs: {
      Search: "Найти",
      Rent_out: "Сдать",
      Orders: "Заказы",
      Chats: "Чаты",
      Profile: "Профиль",
      Moderation: "Модерация"
    },
    userprofile: {
      User_profile: "Профиль пользователя",
      Phone: "Телефон",
      Message: "Сообщение",
      To_chat: "К чату",
      Write: "Написать",
      Login_warn: "Зарегистрируйтесь или войдите в свой аккаунт, чтобы связываться с владельцами вещей.",
      Login: "Войти",
      Enter_name: "Укажите имя.",
      Enter_surname: "Укажите фамилию.",
      Enter_phone: "Укажите телефон.",
      Back: "Назад"
    },
    cardlist: {
      Set_main: "Сделать основной",
      Main: "Основная",
      Pay_by_card: "Оплатить с карты",
      Add_card: "Добавить карту",
      Cancel: "Отмена",
      Delete_q: "Удалить?",
      Delete: "Удалить",
      Your_cards: "Ваши карты",
      Select_card: "Выберите карту",
      Select: "Выбрать"
    },
    ordercalendar: {
      Select_rent_time: "Выберите срок аренды",
      Select_rent_start_end: "Укажите дату начала и конца периода аренды.",
      Code_wrong: "Код не подтвержден. Попробуйте еще раз"
    },
    orderconfirm: {
      Code_wrong: "Код не подтвержден. Попробуйте еще раз",
      Confirm_get_property: "Подтвердите получение имущества",
      Check_property: "Проверьте имущество - внешний вид и функциональность. Ознакомьтесь с условиями договора аренды и акта приема-передачи имущества и подпишите их с помошью SMS-кода.",
      Agree_1: "Имущество в надлежащем виде, с",
      Agree_2: "договором ознакомлен и согласен.",
      Get_sms: "Получить SMS-код",
      Confirm: "Подтвердить",
      New_sms: "Повторное SMS через",
      Sec: "сек.",
      Get_code: "Запросить новый код",
      Confirm_give_property: "Подтвердите передачу имущества",
      Check_and_confirm: "Ознакомьтесь с условиями договора аренды и подпишите его с помошью SMS-кода. Сверьте данные в договоре и паспорте арендатора ДО передачи имущества."
    },
    orderpayment: {
      Order_payment: "Оплата заказа",
      Complete_payment: "Завершите оплату заявки в течение 5 минут.",
      Agreement_1: "Оплачивая заказ, вы подтверждаете свое согласие с условиями",
      Agreement_2: "публичной оферты",
      Agreement_3: "сервиса «Добро в дело», а также с условиями",
      Agreement_4: "договора аренды",
      Agreement_5: "по вашему заказу.",
      Pay_new_card: "Оплатить новой картой",
      Order_document: "Договор аренды",
      Public: "Публичная оферта"
    },
    orderreturn: {
      Property_return_confirm: "Подтвердите возврат имущества",
      Property_return_message: "Внимание, вы возвращаете имущество владельцу. Владелец получит запрос на подтверждение действия, все проверит и подтвердит возврат залога, если залог был оплачен.",
      Property_return: "Возврат имущества",
      Property_check: "Проверьте имущество - внешний вид и функциональность. Подтвердите получение. Залог будет возвращен арендатору.",
      Property_okey: "Имущество в надлежащем виде, претензий к Арендатору не имею.",
      Confirm_return: "Подтвердить возврат",
      Return_property:"Вернуть имущество",
      Code_wrong: "Код не подтвержден. Попробуйте еще раз",
      Sure_q: "Уверены?",
      No: "Нет",
      Yes: "Да",
      Return_act: "Акт передачи имущества",
      Close: "Закрыть"
    },
    productimages: {
      Take_photo: "Снять фото",
      From_gallery: "Из галереи",
      Photos: "Фотографии"
    },
    search: {
      Category: "Категория",
      Category_select: "Выберите категорию",
      Minimum_price: "Мин. цена",
      Maximum_price: "Макс. цена",
      Show_results: "Показать результаты",
      Reset_filters: "Сбросить фильтры",
      Categories: "Категории",
      Item_search: "Поиск вещей",
      All: "Все",
      Favorite: "Избранное",
      Find_me: "найти меня",
      Loading: "Загрузка...",
      Price: "₽/сутки",
      Search_settings: "Параметры поиска"
    },
    store: {
      Products: "Покупки",
      Month_subscription: "Период подписки: Месяц",
      Active: "Активно",
      Restore_purchases: "Восстановить покупки",
      Privacy_policy: "Политика конфиденциальности",
      Eula: "Пользовательское соглашение (EULA)",
      Purchases_restored: "Покупки восстановлены.",
      More_ads: "Публикуйте больше объявлений!"
    },
    verifiation: {
      Documents_check: "Документ отправлен на проверку.",
      Load_photo: "Загрузите фото документа, подтверждающего вашу личность",
      Add_photo: "Добавить фото"
    }

  }
}

export default messages