import api from '@/lib/api'
//import lib from '@/lib/lib'
import {InAppPurchase2} from '@ionic-native/in-app-purchase-2';
import { Capacitor } from '@capacitor/core';

export default {
  namespaced: true,

  state: () => ({
    inappstore: {},
    storeproducts: {}
  }),

  mutations: {
    setStore(state, payload){
      state.inappstore = payload;
    },
    setStoreproducts(state, payload){
      state.storeproducts = payload;
    }    
  },
  actions: {
    initStore({ commit, dispatch }) {
      //console.log(user_id);

      api
          .trans("storeproducts/list", {}, 'get')
          .then((
            // eslint-disable-next-line no-unused-vars 
            resp) => {
              this.showLoader = false;

              var store_obj = InAppPurchase2;
              store_obj.validator = "https://dobrovdelo.ru/api/storeproducts/purchase/" + this.state.user.currentUser.id;

              console.log('THIS STATE!');
              console.log(this.state);
              this.state.storeproducts = resp.data;
              var keys = Object.keys(this.state.storeproducts);
              //console.log(keys);
              var i = 0;

              

              console.log(this.state.storeproducts);
              console.log(Capacitor.isNativePlatform());
              if (Capacitor.isNativePlatform()){
                for(i = 0; i < keys.length; i++){
                  var product_name = this.state.storeproducts[keys[i]].alias;
                  
                  //lib.setGlobalVar('store_product_registered_'+keys[i], true);
                  //if (typeof this.state.storeproducts[keys[i]].registered !== 'undefined') continue;
                  store_obj.register({
                    id: product_name,
                    type: store_obj.PAID_SUBSCRIPTION,
                  });
      
                  // // Register event handlers for the specific product
                  store_obj.when(product_name).registered( (product) => {
                    this.state.storeproducts[product.alias].registered = true;
                    // console.log('Registered: ' + JSON.stringify(product));
                  });
      
                  // Updated
                  store_obj.when(product_name).updated( (product) => {
                    console.log('Updated' + JSON.stringify(product));
                    //lib.setGlobalVar('store_product_'+product.alias, product);
                    
                    // this.state.storeproducts[product.alias].price = product.price;
                    // this.state.storeproducts[product.alias].title = product.title;
                    // this.state.storeproducts[product.alias].description = product.description;
                    delete product.owned;
                    this.state.storeproducts[product.alias] = product;
                  
                  });
                  
                  store_obj.when(product_name).approved( (product) => {
                    console.log('Approved' + JSON.stringify(product));
                    product.verify();
                  });
      
                  store_obj.when(product_name).verified(function(product) {
                    console.log('Verified' + JSON.stringify(product));
                    // обновить статус подписки
                    
                    setTimeout(function(){
                      dispatch("updateProduct", product.alias);
                    }, 2500);
                    
                    //product.finish();
                  });        
                  
      
                  // User closed the native purchase dialog
                  // eslint-disable-next-line
                  store_obj.when(product_name).cancelled( (product) => {
                      console.error('Purchase was Cancelled');
                  });
                
                }
                
                store_obj.refresh(); 
                // Track all store errors
                store_obj.error( (err) => {
                  console.error('Store Error ' + JSON.stringify(err));
                });
        
                
                // Run some code only when the store is ready to be used
                store_obj.ready(() =>  {
                  console.log('Store is ready');
                  //console.log('Products: ' + JSON.stringify(this.store.products));
                  //console.log(JSON.stringify(this.store.get("my_product_id")));
                  
                  // Refresh the status of in-app products
                  commit('setStore', store_obj); 
                  commit('setStoreproducts', this.state.storeproducts); 
                });
              } else{
                for(i = 0; i < keys.length; i++){
                  this.state.storeproducts[keys[i]].loaded = true;
                  this.state.storeproducts[keys[i]].valid = true;
                  commit('setStore', store_obj); 
                  commit('setStoreproducts', this.state.storeproducts);                   
                }
              }

              //this.initialStoreproducts();
          });


      // return new Promise((resolve) => {
      //   api.trans('users/get/'+lib.user_id(), {}, 'get').then((response) => {
      //     console.log(response.data);
      //     commit('setStore', store);
      //     resolve(true);
      //   })
      // });
    },
    updateProduct( {commit, dispatch}, product_alias){
      console.log(this.state.storeproducts);
      console.log(product_alias);
      api
      .trans("storeproducts/get/" + this.state.storeproducts[product_alias].id, {}, 'get')
      .then((resp) => {
        console.log(resp.data);
        this.state.storeproducts[product_alias].owned = resp.data[product_alias].owned;
        this.state.storeproducts[product_alias].expire_date = resp.data[product_alias].expire_date;
        commit('setStoreproducts', this.state.storeproducts);
        dispatch('user/getConfig');
      });
    }
  },
  getters: {
    
    storeproducts(state){
      return state.storeproducts;
    },

    inappstore(state){
      return state.inappstore;
    },
  }
}
