const token = {
  
  exist () { return (window.localStorage.getItem('token') !== null) },
  get () { return window.localStorage.getItem('token') },
  save ( token ) { window.localStorage.setItem('token', token) },
  remove () { window.localStorage.removeItem('token') },

  existToken (key) { return (window.localStorage.getItem(key) !== null) },
  saveToken(key, token) { window.localStorage.setItem(key, token) },
  getToken(key) { return window.localStorage.getItem(key) },
  removeToken(key) { window.localStorage.removeItem(key) }

}
export default token